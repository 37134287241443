<template>
    <div class="box flex_column">
        <div class="box_broder"></div>
        <div class="box_show"></div>
        <div class="head blue flex">
            <div class="head_left mouse">
                <img @click="switchType(1)" src="../../assets/video/qhPanel.png" alt="">
                <template v-if="$store.state.videoCallInfo.switchVideo == 1">
                    <img @click="switchType(2)" v-if="$store.state.videoCallInfo.isMute"
                        src="../../assets/video/gPanel.png" alt="">
                    <img @click="switchType(2)" v-else src="../../assets/video/kPanel.png" alt="">
                </template>
            </div>
            <div class="head_right">
                <div>{{ $store.state.videoCallInfo.switchVideo == 1 ? '强拉视频/视频回传' : '视频呼叫' }}</div>
            </div>
        </div>
        <div class="container flex_grow" ref="containerHeight">
            <div class="container_ql"
                v-if="$store.state.videoCallInfo.switchVideo == 1 && videoList.filter(item => item.isShow).length > 0"
                :style="'height:' + divStyle.height">
                <div :class="['container_qlVideo', item.isShow ? '' : 'none']" :style="'width:' + divStyle.width + ';'"
                    v-for="(item, index) in videoList" :key="index">
                    <div class="container_qlVideo_name">
                        {{ item.name }}
                    </div>
                    <div class="container_qlVideo_video" ref="qlVideo">
                        <!-- controls -->
                        <div style="width:100%;height: 100%;position: relative;margin: 0px auto;"
                            :style="'width:' + divStyle.videoWidth + 'px'">
                            <div class="frame" v-if="item.videoType == 201"></div>
                            <video ref="myVideo" disablePictureInPicture="false" controlslist="nodownload"
                                :style="'width:' + divStyle.videoWidth + 'px;'" :id="item.videoId" autoplay
                                :src="item.url" class="video"></video>
                        </div>
                    </div>
                    <div class="container_qlVideo_right flex mouse">
                        <!-- <el-dropdown trigger="click" @command="handleCommand" style="margin: 0px 15px 0px 10px;">
                            <span class="el-dropdown-link">
                                {{ videoType[videoTypeIndex].name }}<i class="el-icon-caret-bottom el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(item, i) in videoType" :key="i" :command="i">
                                    {{ item.name }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->
                        <img v-if="item.isMute" @click="changeVoice(item, index)"
                            src="../../assets/video/qlVideoSyg1.png" alt="">
                        <img v-else @click="changeVoice(item, index)" src="../../assets/video/qlVideoSyg.png" alt="">
                        <img @click="switchScreen(item)" src="../../assets/video/qlVideoFd.png" alt="">
                        <img v-if="item.videoType == 101" @click="switchCamera(item, index)"
                            src="../../assets/video/screen.png" alt="">
                        <img @click="endEcho(item, index)" src="../../assets/video/qlVideoGd.png" alt="">
                    </div>
                </div>
            </div>
            <div class="tips blue"
                v-if="$store.state.videoCallInfo.callType == 0 && $store.state.videoCallInfo.switchVideo == 0 || $store.state.videoCallInfo.switchVideo == 1 && videoList.filter(item => item.isShow).length <= 0">
                当前没有实时视频
            </div>
        </div>
    </div>
</template>
<script></script>
<script>
import axios from "axios";
import qs from "qs";
import beasconfig from "@/api/beasconfig.js"
const videoUrlapi = beasconfig.videoUrlapi;
import EventBus from "@/eventBus/eventBus.js";
import { _throttle } from "@/method/utils.js";
export default {
    props: {

    },
    data() {
        return {
            videoType: [
                {
                    id: 1,
                    name: '流畅'
                },
                {
                    id: 2,
                    name: '标清'
                },
                {
                    id: 3,
                    name: '高清'
                }
            ],
            videoTypeIndex: 1,
            userArray: [],
            divStyle:{
                width:'100%',
                height: '100%',
                videoWidth:'100%'
            },
            videoList:[],
            userState:"",
            playInList:[],  //播放中
            videoVoiceLIst:[]       //是否开启视频声音列表
        };
    },
    created() {
        let that = this;
        that.userState = JSON.parse(sessionStorage.getItem("userState"));
        let videoList = JSON.parse(sessionStorage.getItem("videoList")); 
        if(videoList && videoList.length > 0){
            this.$store.state.videoCallInfo.forcedpullList = videoList;
        }else{
            this.$store.state.videoCallInfo.forcedpullList = [];
        }
        EventBus.$on("switchVideo", function (type) {
            that.$store.state.videoCallInfo.switchVideo = type;
            that.getVideoList();
            if(type == 1){
                that.start();
            }
        });
        EventBus.$on("EchoUserId", function (user) {        //
            that.userArray = user;
            that.echoList();
        });
        EventBus.$on("butMute1", function () {
            that.switchType(2);
        });
        EventBus.$on("deleteVideo", function (id) {       //删除视频
            // let index = that.videoList.findIndex(item => item.userId == id);
            if(that.playInList.length > 0){     //删除已有播放的视频
                let i = that.playInList.findIndex(item => item == id);
                if(i >= 0){
                    that.playInList.splice(i,1);
                }
            }
            if(that.videoList.length > 0 ){     //隐藏视频
                for (let index = 0; index < that.videoList.length; index++) {
                    const element = that.videoList[index];
                    if(element.userId == id){
                        if(that.$store.state.videoCallInfo.switchVideo == 1){
                            that.emptyVideoFlow(index);
                        }
                        if(that.videoList[index].isShow){
                            that.videoList[index].isShow = false;
                            that.$store.state.videoCallInfo.forcedpullList[index].isShow = false;
                        }
                    }
                }
                that.calculationHeight();
                sessionStorage.setItem('videoList', JSON.stringify(that.videoList));
            }
            if(that.videoVoiceLIst.length > 0){     //删除已静音的视频
                let n = that.videoVoiceLIst.findIndex(item => item.userId == id);
                if(n >= 0){
                    that.videoVoiceLIst.splice(n,1);
                    that.voiceMute(1);
                }
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("switchVideo");
        EventBus.$off("EchoUserId");
        EventBus.$off("butMute1");
        EventBus.$off("deleteVideo");
        this.playInList = [];
        this.$store.state.videoCallInfo.switchVideo = 0;
    },
    mounted() {
        this.getVideoList();
    },
    methods: {
        calculationHeight() {        //计算高度
            let num = this.videoList.filter(item => item.isShow).length;
            var height = this.$refs.containerHeight.offsetHeight;
            if(num == 1){
                this.divStyle.width = '100%;';
                this.divStyle.height = Math.floor(height - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height - 4)* 0.75);
            }else if(num == 2){
                this.divStyle.width = 'calc(50% - 2px)';
                this.divStyle.height = Math.floor(height - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height - 4)* 0.75);
            }else if(num <= 4){
                this.divStyle.width = 'calc(50% - 2px)';
                this.divStyle.height = Math.floor(height / 2 - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height / 2 - 4)* 0.75);
            }else if(num <= 6){
                this.divStyle.width = 'calc(33.33% - 2px)';
                this.divStyle.height = Math.floor(height / 2 - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height / 2 - 2)* 0.75);
            }else if(num <= 9){
                this.divStyle.width = 'calc(33.33% - 2px)';
                this.divStyle.height = Math.floor(height / 3 - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height / 3 - 2)* 0.75);
            }else if(num >= 10){
                this.divStyle.width = 'calc(25% - 2px)';
                this.divStyle.height = Math.floor(height / 3 - 4);
                this.divStyle.videoWidth = Math.round(Math.floor(height / 3 - 2)* 0.75);
            }
            this.divStyle.height = this.divStyle.height + 'px;';
        },
        handleCommand(e) {   //下拉菜单
            this.videoTypeIndex = e;
        },
        getVideoList(){
            let videoList = JSON.parse(JSON.stringify(this.$store.state.videoCallInfo.forcedpullList));
            if(videoList && videoList.length > 0){
                this.videoList = videoList;
                this.calculationHeight();
            }else{
                this.videoList = [];
            }
        },
        switchType(type) {       //切换类型
            if (type == 1) {
                this.playInList = [];
                this.$store.state.videoCallInfo.switchVideo = this.$store.state.videoCallInfo.switchVideo == 0 ? 1 : 0;
                this.getVideoList();
                setTimeout(()=>{
                    this.start();
                },100)
            } else {
                if(this.$store.state.isBroadcast){

                   return
                }
                this.$store.state.videoCallInfo.isMute = !this.$store.state.videoCallInfo.isMute;
                if(this.$store.state.videoCallInfo.isMute){
                    this.videoVoiceLIst = JSON.parse(JSON.stringify(this.videoList));
                }else{
                    this.videoVoiceLIst = [];
                }
                this.voiceMute();
            }
        },
        changeVoice(row,index){ //改变声音
            if(this.$store.state.isBroadcast){

                return
            }
            let id = row.videoId;
            let selfVideo = document.getElementById(id);
            selfVideo.muted = !selfVideo.muted;
            this.videoList[index].isMute = !this.videoList[index].isMute;
            this.$store.state.videoCallInfo.forcedpullList[index].isMute = !this.$store.state.videoCallInfo.forcedpullList[index].isMute;
            let i = this.videoVoiceLIst.findIndex(item => item.videoId == id);
            if(this.videoList[index].isMute){
                this.videoVoiceLIst.push(row);
            }else{
                this.videoVoiceLIst.splice(i,1);
            }
            if(this.videoVoiceLIst.length == 0){
                this.$store.state.videoCallInfo.isMute = false;
            }else{
                this.$store.state.videoCallInfo.isMute = true;
            }
            sessionStorage.setItem('videoList', JSON.stringify(this.videoList));
        },
        switchScreen(row){     //切换全屏
            let id = row.videoId;
            let selfVideo = document.getElementById(id);
            if (selfVideo.requestFullscreen) {       // W3C
                selfVideo.requestFullscreen();
            } else if (selfVideo.mozRequestFullScreen) {     // Firefox
                selfVideo.mozRequestFullScreen();
            } else if (selfVideo.webkitRequestFullScreen) { // Webkit
                selfVideo.webkitRequestFullScreen();
            }
        },
        switchCamera(row,index){     //切换摄像头
              let parmas = {      //关闭强拉视频
                      fromUid: this.$store.state.userInfo.User_ID,
                      toUids: row.userId,
                      data:0          //1前置 0后置
                  };
              axios.post(`${videoUrlapi}-${this.userState.server}/rtc/msg/change/direction`, qs.stringify(parmas),
                      {
                          headers: beasconfig.tokenSl
                      }
                  ).then(res => {
                      if(res.data.code == 200){
                          this.videoList[index].isCamera = !this.videoList[index].isCamera;
                          sessionStorage.setItem('videoList', JSON.stringify(this.videoList));
                      }
              })
        },
        start(type){        //开始
            setTimeout(()=>{
                if(this.videoList.length > 0){
                        for (const i in this.videoList) {
                            if(type == 1){
                                this.emptyVideoFlow(i);
                            }
                            this.start_play(i)
                        }
                        this.voiceMute(1);
                }
            },500)
        },
        emptyVideoFlow(i){       //清空流
            if(this.videoList[i]){
                let id = this.videoList[i].videoId;
                var remote = document.getElementById(id);
                if(remote) {
                        this.videoList[i].play = null;
                        remote.srcObject = null;
                        remote.load();
                }
            }
        },
        voiceMute(type){        //全部静音
            let priopity = this.getPriority(); 
            for (const i in this.videoList) {
                let id =this.videoList[i].videoId;
                let selfVideo = document.getElementById(id);
                if(selfVideo){
                    if(priopity){
                        selfVideo.muted = true;
                        this.videoList[i].isMute = false;
                        this.$store.state.videoCallInfo.forcedpullList[i].isMute = false;
                    }else{
                        if(type == 1){  //开始播放流时该是播放就是播放，是暂停就是暂停。默认暂停。
                            selfVideo.muted = !this.videoList[i].isMute;
                        }else{
                            if(this.$store.state.videoCallInfo.isMute){
                                selfVideo.muted = false;
                                this.videoList[i].isMute = true;
                                this.$store.state.videoCallInfo.forcedpullList[i].isMute = true;
                            }else{
                                selfVideo.muted = true;
                                this.videoList[i].isMute = false;
                                this.$store.state.videoCallInfo.forcedpullList[i].isMute = false;
                            }
                        }
                        let bool = this.videoVoiceLIst.some(item => item.videoId == this.videoList[i].videoId)
                        if(this.videoVoiceLIst.length > 0 && type == 0 && !priopity || this.videoVoiceLIst.length > 0 && type == 1){
                            if(bool){
                                selfVideo.muted = false;
                                this.videoList[i].isMute = true;
                                this.$store.state.videoCallInfo.forcedpullList[i].isMute = true;
                            }
                        }
                    }
                    
                    selfVideo.addEventListener("click", mouseHandler, false);
                    function mouseHandler(event) {      //阻止视频video标签点击视频区域播放暂停
                            // 阻止视频默认点击事件
                            event.preventDefault()
                    }
                }
            }
            if(type == 0 && this.videoVoiceLIst.length > 0 && !priopity){
                this.$store.state.videoCallInfo.isMute = true;
            }
            if(type == 1){
                let bool = this.videoList.some(item => item.isMute && item.isShow);        //当有用户是开启声音的时候全局静音按键为开启
                if(bool){
                    this.$store.state.videoCallInfo.isMute = true;
                }else{
                    this.$store.state.videoCallInfo.isMute = false;
                }
            }
            sessionStorage.setItem('videoList', JSON.stringify(this.videoList));
        },
        endEcho(row,index){      //结束回传
            let i = this.playInList.findIndex(item => item == row.userId);
            let n = this.videoVoiceLIst.findIndex(item => item.userId == row.userId);
            if(row.videoType == 201){
                this.emptyVideoFlow(index);
                this.videoList[index].isShow = false;
                sessionStorage.setItem('videoList', JSON.stringify(this.videoList));
                this.$store.state.videoCallInfo.forcedpullList[index].isShow = false;
                if(i >= 0){
                    this.playInList.splice(i,1);
                }
                if(this.videoVoiceLIst.length > 0){     //删除已静音的视频
                    if(n >= 0){
                        this.videoVoiceLIst.splice(n,1);
                        this.voiceMute(1);
                    }
                }
                // var videoList = this.videoList.filter(item => item.isShow);
                // if(videoList.length <= 0){
                //     EventBus.$emit('voiceMute',0);
                // }
                this.voiceMute(1);
                this.calculationHeight();
            }else{
                let parmas = {      //关闭强拉视频
                    fromUid: this.$store.state.userInfo.User_ID,
                    toUids: row.userId,
                    type: 302,      //302结束回传
                    data:2          //2是关闭强拉
                };
                axios.post(`${videoUrlapi}-${this.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                    {
                        headers: beasconfig.tokenSl
                    }
                ).then(res => {
                    if(res.data.code == 200){
                        this.emptyVideoFlow(index);
                        this.videoList[index].isShow = false;
                        sessionStorage.setItem('videoList', JSON.stringify(this.videoList));
                        this.$store.state.videoCallInfo.forcedpullList[index].isShow = false;
                        if(i >= 0){
                            this.playInList.splice(i,1);
                        }
                        if(this.videoVoiceLIst.length > 0){     //删除已静音的视频
                            if(n >= 0){
                                this.videoVoiceLIst.splice(n,1);
                                this.voiceMute(1);
                            }
                        }
                        this.calculationHeight();
                    }
                })
            }
        },
        start_play(i){       //播放
                let that = this;
                let id = that.videoList[i].videoId;
                let bool = that.playInList.some(item => item == that.videoList[i].userId);      //判断是否已经推送流了
                if(bool || that.videoList[i].isShow == false){

                  return
                }
                // if(this.$store.state.videoCallInfo.isMute){
                //     document.getElementById(id).muted = false;
                //     that.videoList[i].isMute = true;
                // }else{
                //     document.getElementById(id).muted = true;
                //     that.videoList[i].isMute = false;
                // }
                that.playInList.push(that.videoList[i].userId);
                if(that.$store.state.videoCallInfo.isMute == false){
                    that.videoList[i].isMute = false
                    document.getElementById(id).muted = false;
                }
                if(document.getElementById(id) && document.getElementById(id).muted){
                    document.getElementById(id).muted = !that.videoList[i].isMute;      //当前点了静音的话就是静音
                }
                that.videoList[i].play = new ZLMRTCClient.Endpoint(
                        {
                            element: document.getElementById(id),// video 标签
                            debug: false,// 是否打印日志
                            zlmsdpUrl:that.videoList[i].url,//流地址
                            simulcast:false,
                            useCamera:false,
                            audioEnable:true,
                            videoEnable:true,
                            recvOnly:true,
                            resolution:{w:480,h:640},
                            usedatachannel:false,
                        }
                    );
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,function(e)
                    {// ICE 协商出错
                        console.log('ICE 协商出错')
                    });
            
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,function(e)
                    {//获取到了远端流，可以播放
                        console.log('播放成功',e.streams)
                    });
            
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,function(e)
                    {// offer anwser 交换失败
                        console.log('offer anwser 交换失败',e)
                        that.videoList[i].isShow = false;
                        sessionStorage.setItem('videoList', JSON.stringify(that.videoList));
                        that.$store.state.videoCallInfo.forcedpullList[i].isShow = false;
                        // that.stop();
                    });
            
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,function(s)
                    {// 获取到了本地流

                    });

                    that.videoList[i].play.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,function(s)
                    {// 获取本地流失败
            
                    console.log('获取本地流失败')
                    });

                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,function(state)
                    {// RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
                    console.log('当前状态==>',state)
                    // let url = that.videoList[i].url;
                    // let n = url.substring(url.indexOf('?') + 1).split('&');
                    // let id = '';
                    // n.forEach(item =>{
                    //     console.log(item.indexOf('sid='))
                    //     let i = item.indexOf('sid=');
                    //     if(i != -1){
                    //         id = item.substring(4);
                    //     }
                    // })
                    // || state == "disconnected" failed
                        // if(state == "disconnected"){  //链接失败就关闭视频      //挂断不应该是失败吧
                        //     console.log(that.videoList)
                        //     console.log(that.videoList[i])
                        //     let n = that.videoList.findIndex(item => item.videoId == that.videoList[i].videoId);
                        //     if(n >= 0 ){
                        //         that.videoList[n].isShow = false;
                        //         // sessionStorage.setItem('videoList', JSON.stringify(that.videoList));
                        //     }
                        // }
                    });

                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,function(event)
                    {
                    console.log('rtc datachannel 打开 :',event)
                    });

                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,function(event)
                    {
                    console.log('rtc datachannel 消息 :',event.data)
                    // document.getElementById('msgrecv').value = event.data
                    });
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,function(event)
                    {
                        console.log('rtc datachannel 错误 :',event)
                    });
                    that.videoList[i].play.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,function(event)
                    {
                        console.log('rtc datachannel 关闭 :',event)
                    });
        },
        echoList() {       //回传列表
            let that = this;
            let parmas = {
                fromUid: that.$store.state.userInfo.User_ID,
                toUids: that.userArray,
                type: 100
            };
            axios.post(`${videoUrlapi}-${that.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                {
                    headers: beasconfig.tokenSl
                }
            ).then(res => {
                // console.log(res)
                if (res.data.code != 200) {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        into:_throttle(function(){
            this.videoList = JSON.parse(JSON.stringify(this.$store.state.videoCallInfo.forcedpullList));
            this.getVideoList();
            this.start();
        },1000),
        getPriority(){      //优先级
            let bool = false;
            if(this.$store.state.userSpeech.isShow){
                bool = true;
            }
            if(this.$store.state.userSpeech.isBool){
                bool = true;
            }
            // if(this.$store.state.videoCallInfo.isAnswerVideo){
            //     bool = true;
            // }
            if(this.$store.state.voiceCallInfo.callType > 0){
                bool = true;
            }
            if(this.$store.state.userSpeech.isBool){
                bool = true;
            }
            if(this.$store.state.isBroadcast){
                bool = true;
            }
            return bool
        }
    },
    watch: {
        //监听事件
        // "$store.state.videoCallInfo.echoList"(data) {
        //     if (data.length > 0 && this.$store.state.videoCallInfo.switchVideo == 1) {
        //         // this.into();
        //         this.$store.state.videoCallInfo.echoList = [];
        //     }
        // },
        "$store.state.videoCallInfo.forcedpullList"(data) {        //切换
            if (data.length > 0 && this.$store.state.videoCallInfo.switchVideo == 1) {
                this.into();
                this.$store.state.videoCallInfo.echoList = [];
            }
        },
        "$store.state.isBroadcast"(bool) {        //语音广播
            //对数据执行操作
            if (bool == true) {
                this.$store.state.videoCallInfo.isMute = false;
                this.voiceMute();
            }else{
                this.$store.state.videoCallInfo.isMute = true;
                this.voiceMute(0);
            }
        },
        "$store.state.videoCallInfo.isAnswerVideo"(bool) {        //是否接听了视频通话
            //对数据执行操作
            if(bool){
                this.$store.state.videoCallInfo.isMute = false;
                this.voiceMute();
            }else{
                this.voiceMute(0);
            }
        },
        "$store.state.voiceCallInfo.callType"(type) {        //是否有语音通话
            //对数据执行操作
            if(type > 0){
                this.$store.state.videoCallInfo.isMute = false;
                this.voiceMute();
            }else{
                this.voiceMute(0);
            }
        },
        "$store.state.userSpeech.isShow"(bool) {        //自己当前是否在讲话
            if(bool){
                this.$store.state.videoCallInfo.isMute = false;
                this.voiceMute();
            }else{
                this.voiceMute(0);
            }
        },
        "$store.state.userSpeech.isBool"(bool) {        //是否有人在讲话
            if(bool){
                this.$store.state.videoCallInfo.isMute = false;
                this.voiceMute();
            }else{
                this.voiceMute(0);
            }
        }
    },
};
</script>

<style scoped lang="less">
.frame {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 2px solid #4affff;
    position: absolute;
    left: 0px;
    top: 0px;
    // left: 0;
    z-index: 1;
}

.box {
    width: 100%;
    height: 100%;
    background-color: #08222e;
    position: relative;
    overflow: hidden;

    &_broder{
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #034050;
        position: relative;
        z-index: 1;
    }

    &_show{
        width: 40px;
        height: 100%;
        position: absolute;
        left: -40px;
        top: 0px;
        box-shadow: 4px 6px 51px 0px #00090B;
    }

    .head {
        width: 100%;
        height: 80px;
        margin: auto;
        position: relative;

        &_left {
            position: relative;
            z-index: 10;
            margin-left: 20px;

            img {
                width: 29px;
                height: 29px;
                margin-left: 20px;
            }
        }

        &_right {
            width: 100%;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 15px;
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }

    .container {
        width: 100%;
        // height: calc(100% - 46px);
        overflow: auto;

        .container_ql {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &_qlVideo {
            width: calc(25% - 2px);
            // width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            border-right: 1px solid #666;
            border-bottom: 1px solid #666;
            position: relative;

            &_name {
                flex: 1;
                color: #ffffff;
                padding: 0px 10px 0px 20px;
                text-align: left;
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 3;
            }

            &_video {
                width: 100%;
                height: 100%;

                .video {
                    width: calc(100%-2px);
                    height: 100%;
                    background-color: #ccc;
                }
            }

            &_right {
                // width: 180px;
                height: 35px;
                background-color: #000;
                margin: 0px 10px;
                padding: 0px 20px;
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 3;

                .el-dropdown-link {
                    color: #ffffff;
                }

                img {
                    margin-right: 20px;
                }

                img:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}

.blue {
    color: #4affff;
}

.tips {
    width: 100%;
    height: 100%;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.flex {
    display: flex;
    align-items: center;
}

.none {
    display: none !important;
}

//音量按钮
video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}

//音量的控制条
video::-webkit-media-controls-volume-slider {
    display: none;
}

//播放按钮
video::-webkit-media-controls-play-button {
    display: none;
}

//进度条
video::-webkit-media-controls-timeline {
    display: none;
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
    display: none;
}

//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
</style>